/* @flow */

import type { ParentItem } from "app/Header/Drawer";
import type { Match } from "react-router";

import React, { useContext, useEffect } from "react";
import { Link, NavLink, useLocation } from "react-router-dom";
import { Route, Switch } from "react-router";
import cn from "classnames";
import { useTranslate } from "@awardit/react-use-translate";
import { StoreInfoContext } from "entrypoint/shared";
import { useData } from "crustate/react";
import { QuoteData } from "state/data";
import { Wrapper, CartMini } from "@crossroads/ui-components";
import ButtonLink from "components/ButtonLink";
import Drawer from "app/Header/Drawer";
import CartCounter from "app/Header/CartCounter";
import useCheckoutSteps from "helpers/hooks/use-checkout-steps";
import SearchMenu from "../SearchMenu";
import Logo from "components/Logo";
import HamburgerMenu from "app/Header/HamburgerMenu";
import MobileNavHeader from "../MobileNavHeader";
import { useUi, MODE } from "helpers/ui";
import SearchIcon from "icons/search.svg";
import CustomerServiceIcon from "icons/customer-service.svg";
import HamburgerIcon from "icons/hamburger.svg";
import CloseIcon from "icons/close.svg";

import styles from "./styles.scss";

const CheckoutSteps = (): React$Node => {
  const { steps, currentStep } = useCheckoutSteps();

  return (
    <div className={styles.steps}>
      {steps.map((step, id) => (
        <Link
          key={step.id}
          className={cn(styles.step, { [styles.active]: step.id === currentStep.id })}
          to={step.link}
        >
          {`${id + 1}. ${step.title}`}
        </Link>
      ))}
    </div>
  );
};

const NavLinks = (): React$Node => {
  const t = useTranslate();
  const {
    viewMode,
    setViewMode,
  } = useUi();

  const navLinkIsActive = (match: Match) => {
    if (!match) {
      return false;
    }

    if (viewMode !== MODE.NORMAL) {
      return false;
    }

    return true;
  };

  const toggleCategories = () =>
    setViewMode(viewMode === MODE.CATEGORIES ? MODE.NORMAL : MODE.CATEGORIES);
  const toggleBrands = () =>
    setViewMode(viewMode === MODE.BRANDS ? MODE.NORMAL : MODE.BRANDS);

  return (
    <>
      <NavLink
        to="/all-products"
        activeClassName={styles.active}
        className={cn(
          styles.item,
          styles.item__allproducts
        )}
        isActive={navLinkIsActive}
        onClick={() => {
          setViewMode(MODE.NORMAL);
        }}
      >
        <span>{t("ALL_PRODUCTS.TITLE")}</span>
      </NavLink>
      <span
        className={
          cn(
            styles.item,
            styles.item__categories,
            { [styles.active]: viewMode === MODE.CATEGORIES }
          )
        }
        onClick={toggleCategories}
      >
        <span>{t("HEADER.CATEGORIES")}</span>
      </span>

      <span
        className={
          cn(
            styles.item,
            styles.item__brands,
            { [styles.active]: viewMode === MODE.BRANDS }
          )
        }
        onClick={toggleBrands}
      >
        <span>{t("HEADER.BRANDS")}</span>
      </span>
    </>
  );
};

const DesktopNavHeader = ({
  heading,
  onClose,
}: { heading: string, onClose: () => void }): React$Node => (
  <Wrapper className={styles.desktopNavHeader}>
    <h3>{heading}</h3>
    <ButtonLink onClick={onClose}><CloseIcon /></ButtonLink>
  </Wrapper>
);

const mapBrands = (brands: $ReadOnlyArray<string>): Array<ParentItem> => {
  const grouped = {};

  brands.forEach(brand => {
    const firstLetter = brand[0].toLowerCase();
    const array = (grouped[firstLetter] || (grouped[firstLetter] = []));

    array.push({
      name: brand,
      url: `/brand/${encodeURIComponent(brand)}`,
    });
  });

  return Object.keys(grouped)
    .sort((a, b) => a.localeCompare(b))
    .map(letter => ({
      name: letter.toUpperCase(),
      url: null,
      children: grouped[letter]
        .sort((a, b) => a.name.localeCompare(b.name, { caseInsensitive: true })),
    }));
};

const MobileSubNavigations = ({ onClose }: {
  onClose: () => void,
}) => {
  const t = useTranslate();
  const { categories, brands } = useContext(StoreInfoContext);

  const {
    setSubNavOpen,
    viewMode,
    setViewMode,
  } = useUi();

  const closeMenu = () => setViewMode(MODE.NORMAL);

  return (
    <>
      {viewMode === MODE.SEARCH &&
        <nav className={cn(styles.mobile, styles.subNav, styles.search)}>
          <SearchMenu heading={t("HEADER.SEARCH")} onClose={onClose} onItemClick={closeMenu} onBack={() => setSubNavOpen(false)} />
        </nav>
      }

      {viewMode === MODE.CATEGORIES &&
        <nav className={cn(styles.mobile, styles.subNav, styles.categories)}>
          <MobileNavHeader
            heading={t("HEADER.CATEGORIES")}
            onBack={() => setSubNavOpen(false)}
            onClose={onClose}
          />

          <Drawer items={categories} />
        </nav>
      }

      {viewMode === MODE.BRANDS &&
        <nav className={cn(styles.mobile, styles.subNav, styles.brands)}>
          <MobileNavHeader
            heading={t("HEADER.BRANDS")}
            onBack={() => setSubNavOpen(false)}
            onClose={onClose}
          />

          <Drawer items={mapBrands(brands)} />
        </nav>
      }
    </>
  );
};

const DesktopSubNavigations = ({ onClose }: {
  onClose: () => void,
}): React$Node => {
  const t = useTranslate();
  const { categories, brands } = useContext(StoreInfoContext);

  const {
    setHamburgerOpen,
    setSubNavOpen,
    viewMode,
    setViewMode,
  } = useUi();

  const closeMenu = () => setViewMode(MODE.NORMAL);

  return (
    <>
      {viewMode === MODE.CATEGORIES &&
        <nav className={cn(styles.desktop, styles.subNav, styles.categories)}>
          <DesktopNavHeader heading={t("HEADER.CATEGORIES")} onClose={onClose} />
          <Drawer
            setHamburgerOpen={setHamburgerOpen}
            heading={t("HEADER.CATEGORIES")}
            items={categories}
            onClose={onClose}
            onItemClick={closeMenu}
            onBack={() => setSubNavOpen(false)}
          />
        </nav>
      }

      {viewMode === MODE.BRANDS &&
        <nav className={cn(styles.desktop, styles.subNav, styles.brands)}>
          <DesktopNavHeader heading={t("HEADER.BRANDS")} onClose={onClose} />
          <Drawer
            setHamburgerOpen={setHamburgerOpen}
            heading={t("HEADER.BRANDS")}
            items={mapBrands(brands)}
            onClose={onClose}
            onItemClick={closeMenu}
            onBack={() => setSubNavOpen(false)}
          />
        </nav>
      }
    </>
  );
};

const Nav = ({
  onCheckout,
}: { onCheckout: boolean }): React$Node => {
  const t = useTranslate();
  const location = useLocation();
  const quoteData = useData(QuoteData);
  const showHeader = true;
  const {
    setHamburgerOpen,
    toggleHamburger,
    setSubNavOpen,
    viewMode,
    setViewMode,
    closeHamburger,
  } = useUi();

  const toggleSearch = () =>
    setViewMode(viewMode === MODE.SEARCH ? MODE.NORMAL : MODE.SEARCH);

  const navLinkIsActive = (match: Match) => {
    if (!match) {
      return false;
    }

    if (viewMode !== MODE.NORMAL) {
      return false;
    }

    return true;
  };

  // Close menus when switching page
  useEffect(() => {
    setViewMode(MODE.NORMAL);
    setSubNavOpen(false);
    setHamburgerOpen(false);
  }, [setViewMode, setSubNavOpen, setHamburgerOpen, location.pathname]);

  return (
    <div className={cn(styles[`mode__${viewMode}`], { [styles.hide]: onCheckout })}>
      <div className={styles.headerNav}>
        <Wrapper className={styles.wrapper}>
          {viewMode === MODE.SEARCH &&
            <div className={cn(styles.desktop, styles.subNav, styles.search)}>
              <SearchMenu onClose={() => toggleSearch()} />
            </div>
          }
          <div className={styles.left}>
            <Link className={cn(styles.item, styles.item__logo)} to="/">
              <Logo
                className={styles.logo}
                onClick={() => setViewMode(MODE.NORMAL)}
              />
            </Link>
          </div>
          <div className={styles.right}>
            {viewMode !== MODE.SEARCH ? (
              <div className={styles.links}>
                <Switch>
                  <Route path="/checkout/success" component={NavLinks} />
                  <Route path="/checkout" component={CheckoutSteps} />
                  <Route component={NavLinks} />
                </Switch>
              </div>
            ) : null}
            <nav className={styles.nav}>
              {!onCheckout && viewMode !== MODE.SEARCH &&
                <div
                  className={cn(
                    styles.item,
                    styles.item__search,
                    { [styles.active]: viewMode === MODE.SEARCH }
                  )}
                  onClick={toggleSearch}
                >
                  <SearchIcon />
                </div>
              }

              <NavLink
                to={t("CUSTOMER_SERVICE.LINK")}
                activeClassName={styles.active}
                className={cn(styles.item, styles.item__customerService)}
                isActive={navLinkIsActive}
              >
                <span className="sr-only">{t("CUSTOMER_SERVICE.TEXT")}</span>
                <CustomerServiceIcon aria-hidden="true" focusable="false" />
              </NavLink>

              {!onCheckout &&
              <CartCounter
                className={cn(styles.item, styles.item__cartCounter)}
                isOpen={viewMode === MODE.CART}
                openMiniCart={() =>
                  setViewMode(viewMode === MODE.CART ? MODE.NORMAL : MODE.CART)
                } />}
            </nav>
          </div>

          <span
            className={cn(styles.item, styles.hamburger, styles.padding)}
            onClick={toggleHamburger}
          >
            <HamburgerIcon />
          </span>

          {viewMode === MODE.CART &&
            <CartMini
              quoteData={quoteData}
              showHeader={showHeader}
              altUrl="/checkout"
              ctaUrl="/checkout/overview"
              className={cn(styles.desktop, styles.miniCart)}
              onClose={() => setViewMode(MODE.NORMAL)}
            />
          }
        </Wrapper>

        <HamburgerMenu>
          <MobileSubNavigations
            className={styles.mobile}
            onClose={() => closeHamburger(styles.animationDuration)}
          />
        </HamburgerMenu>
      </div>

      <DesktopSubNavigations
        onClose={() => setViewMode(MODE.NORMAL)}
      />

      {viewMode === MODE.CART &&
        <CartMini
          quoteData={quoteData}
          showHeader={showHeader}
          altUrl="/checkout"
          ctaUrl="/checkout/overview"
          className={cn(styles.mobile, styles.miniCart)}
          onClose={() => setViewMode(MODE.NORMAL)}
        />
      }

      {viewMode !== MODE.NORMAL &&
        <div className={styles.dim} onClick={() => setViewMode(MODE.NORMAL)} />
      }
    </div>
  );
};

export default Nav;
