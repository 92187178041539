/* @flow */

import React from "react";
import cn from "classnames";
import { Link } from "react-router-dom";
import { useHistory } from "react-router";
import { useTranslate } from "@awardit/react-use-translate";
import useCheckoutSteps from "helpers/hooks/use-checkout-steps";
import { Wrapper } from "@crossroads/ui-components";
import ButtonLink from "components/ButtonLink";

import Logo from "components/Logo";
import ChevronIcon from "icons/chevron.svg";
import CustomerServiceIcon from "icons/customer-service.svg";
import SystemMessages from "components/SystemMessages";

import styles from "./styles.scss";

type Props = {
  className?: string,
};

const Header = ({ className }: Props): React$Node => {
  const t = useTranslate();
  const history = useHistory();
  const { steps, currentStep } = useCheckoutSteps();

  return (
    <>
      <SystemMessages />
      <div className={cn(className, styles.stepHeader)}>
        <Wrapper>
          <div className={styles.content}>
            <div className={styles.left}>
              <Link className={styles.logo} to="/">
                <Logo />
              </Link>

              <div className={styles.steps__desktop}>
                {steps.map(x => (
                  <Link
                    key={x.id}
                    to={x.link}
                    className={cn(styles.step, { [styles.current]: x.id === currentStep.id })}
                  >
                    {`${x.key}. ${x.title}`}
                  </Link>
                ))}
              </div>
            </div>

            <div className={styles.right}>
              <Link to="/customer-service">
                <CustomerServiceIcon />
              </Link>
            </div>
          </div>

          <div className={styles.steps__mobile}>
            <div className={styles.step}>
              <div className={styles.counter}>
                {currentStep.key} {t("CART.OF")} {steps.length}
              </div>
              <div className={styles.title}>
                {t(`CHECKOUT.STEPS.${currentStep.id.toUpperCase()}.SHORT`)}
              </div>
            </div>

            <ButtonLink className={styles.back} onClick={() => history.goBack()}>
              <ChevronIcon />
            </ButtonLink>
          </div>
        </Wrapper>
      </div>
    </>
  );
};

export default Header;
