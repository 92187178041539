/* @flow */

import type { Quote } from "shop-state/types";

import React, { useEffect, useState } from "react";
import { useAnalytics } from "context/analytics";

import Free from "./Free";
import CollectorCheckout from "./CollectorCheckout";
import { CmsData } from "state/data";

type Props = {
  quote: Quote,
};

const Overview = ({ quote }: Props): React$Node => {
  const analytics = useAnalytics();
  const [analyticsSent, setAnalyticsSent] = useState(false);

  useEffect(() => {
    if (!analyticsSent && quote.items.length > 0) {
      analytics.registerBeginCheckoutProcess(quote, quote.grandTotal.incVat);
      setAnalyticsSent(true);
    }
  }, [quote, analytics, analyticsSent]);

  if (quote.grandTotal.incVat === 0) {
    return (
      <CmsData.Provider url="terms">
        <Free />
      </CmsData.Provider>
    );
  }

  return <CollectorCheckout />;
};

export default Overview;
