/* @flow */

import type { Storage } from "crustate";
import type { Client } from "@awardit/graphql-ast-client";
import type { QuoteRequest, QuoteResponse } from "@crossroads/shop-state/quote";
import { addMessage } from "@crossroads/shop-state/messages";
import { removeExampleEmail } from "helpers/utils";

import {
  QUOTE_INIT_REQUEST,
  QUOTE_INIT_RESPONSE,
  QUOTE_LOAD_REQUEST,
  QUOTE_LOAD_RESPONSE,
  QUOTE_ADD_ITEM_REQUEST,
  QUOTE_ADD_ITEM_RESPONSE,
  QUOTE_REMOVE_ITEM_REQUEST,
  QUOTE_REMOVE_ITEM_RESPONSE,
  QUOTE_UPDATE_ITEM_REQUEST,
  QUOTE_UPDATE_ITEM_RESPONSE,
  QUOTE_SET_ADDRESSES_REQUEST,
  QUOTE_SET_ADDRESSES_RESPONSE,
  QUOTE_SET_PAYMENT_METHOD_STRIPE_REQUEST,
  QUOTE_SET_PAYMENT_METHOD_STRIPE_RESPONSE,
  QUOTE_SET_EMAIL_REQUEST,
  QUOTE_SET_EMAIL_RESPONSE,
} from "@crossroads/shop-state/quote";

import {
  quote as quoteQuery,
  addToCart,
  removeQuoteItem as removeQuoteItemMutation,
  updateQuoteItemQty as updateQuoteItemQtyMutation,
  setQuoteShippingAddress,
  setQuoteShippingMethodToCheapest,
  setQuoteBillingAddress,
  setQuoteBillingAddressAsShippingAddress,
  setQuotePaymentMethodStripe,
  setQuoteDiscountCode as setQuoteDiscountCodeMutation,
  removeQuoteDiscountCode,
  removeUnorderableQuoteItems,
  setQuoteEmail as setQuoteEmailQuery,
} from "queries";

const QUOTE_SET_DISCOUNT_CODE_REQUEST: "quote/set_discount_code/request" = "quote/set_discount_code/request";
const QUOTE_SET_DISCOUNT_CODE_RESPONSE: "quote/set_discount_code/response" = "quote/set_discount_code/response";

const QUOTE_REMOVE_DISCOUNT_CODE_REQUEST: "quote/remove_discount_code/request" = "quote/remove_discount_code/request";
const QUOTE_REMOVE_DISCOUNT_CODE_RESPONSE: "quote/remove_discount_code/response" = "quote/remove_discount_code/response";

const registerClient = (storage: Storage, client: Client<{}>) => {
  storage.addEffect({
    effect: async () => {
      const { removeUnorderableQuoteItems: removedItems } =
        await client(removeUnorderableQuoteItems);

      for (const item of removedItems) {
        storage.broadcastMessage(addMessage({
          translationKey: "ITEM_REMOVED_FROM_CART",
          variable: {
            name: item.product.name,
          },
        }, "success"));
      }

      const { quote } = await client(quoteQuery);

      return ({
        tag: QUOTE_INIT_RESPONSE,
        data: removeExampleEmail(quote),
      }: QuoteResponse);
    },
    subscribe: { [QUOTE_INIT_REQUEST]: true },
  });

  storage.addEffect({
    effect: async () => {
      const { quote } = await client(quoteQuery);

      return ({
        tag: QUOTE_LOAD_RESPONSE,
        data: removeExampleEmail(quote),
      }: QuoteResponse);
    },
    subscribe: { [QUOTE_LOAD_REQUEST]: true },
  });

  storage.addEffect({
    effect: async (msg: QuoteRequest) => {
      if (msg.tag !== QUOTE_ADD_ITEM_REQUEST) {
        return;
      }

      const params = msg.bundleOptions ? {
        buyRequest: msg.buyRequest,
        qty: msg.qty,
        bundleOptions: msg.bundleOptions,
      } : {
        buyRequest: msg.buyRequest,
        qty: msg.qty,
      };

      const [{ addQuoteItem }, , { quote }] = await Promise.all([
        client(addToCart, params),
        client(setQuoteShippingMethodToCheapest),
        client(quoteQuery),
      ]);

      if (addQuoteItem.result !== "success") {
        storage.broadcastMessage(addMessage(addQuoteItem.result, "error"));
      }

      return ({
        tag: QUOTE_ADD_ITEM_RESPONSE,
        data: removeExampleEmail(quote),
      }: QuoteResponse);
    },
    subscribe: { [QUOTE_ADD_ITEM_REQUEST]: true },
  });

  storage.addEffect({
    effect: async (msg: QuoteRequest) => {
      if (msg.tag !== QUOTE_UPDATE_ITEM_REQUEST) {
        return;
      }

      const [{ updateQuoteItemQty }, , { quote }] = await Promise.all([
        client(updateQuoteItemQtyMutation, {
          itemBuyRequest: msg.itemBuyRequest,
          qty: msg.qty,
        }),
        client(setQuoteShippingMethodToCheapest),
        client(quoteQuery),
      ]);

      if (updateQuoteItemQty.result !== "success") {
        storage.broadcastMessage(addMessage(updateQuoteItemQty.result, "error"));
      }

      return ({
        tag: QUOTE_UPDATE_ITEM_RESPONSE,
        data: removeExampleEmail(quote),
      }: QuoteResponse);
    },
    subscribe: { [QUOTE_UPDATE_ITEM_REQUEST]: true },
  });

  storage.addEffect({
    effect: async (msg: QuoteRequest) => {
      if (msg.tag !== QUOTE_REMOVE_ITEM_REQUEST) {
        return;
      }

      const [{ removeQuoteItem }, , { quote }] = await Promise.all([
        client(removeQuoteItemMutation, { itemBuyRequest: msg.itemBuyRequest }),
        client(setQuoteShippingMethodToCheapest),
        client(quoteQuery),
      ]);

      if (removeQuoteItem.result !== "success") {
        // TODO: translate error!
        storage.broadcastMessage(addMessage(removeQuoteItem.result, "error"));
      }

      return ({
        tag: QUOTE_REMOVE_ITEM_RESPONSE,
        data: removeExampleEmail(quote),
      }: QuoteResponse);
    },
    subscribe: { [QUOTE_REMOVE_ITEM_REQUEST]: true },
  });

  storage.addEffect({
    effect: async (msg: QuoteRequest) => {
      if (msg.tag === QUOTE_SET_ADDRESSES_REQUEST) {
        const errors = {};
        const promises = [
          client(setQuoteEmailQuery, { email: msg.email }),
          client(setQuoteBillingAddress, { address: msg.billing }),
        ];

        if (msg.shipToSameAddress) {
          promises.push(client(setQuoteBillingAddressAsShippingAddress));
        }
        else {
          const shipping = {
            city: "",
            countryCode: "SE",
            firstname: "",
            lastname: "",
            postcode: "",
            street: [],
            telephone: "",
            ...msg.shipping,
          };

          promises.push(client(setQuoteShippingAddress, { address: shipping }));
        }

        promises.push(client(setQuoteShippingMethodToCheapest));

        const [{ setQuoteEmail }] = await Promise.all(promises);

        if (!["success", "notModified"].includes(setQuoteEmail.result)) {
          errors.email = setQuoteEmail.result;
          storage.broadcastMessage(addMessage(setQuoteEmail.result, "error"));
        }

        const { quote: data } = await client(quoteQuery);

        return ({
          tag: QUOTE_SET_ADDRESSES_RESPONSE,
          data: removeExampleEmail(data),
          errors,
        }: QuoteResponse);
      }
    },
    subscribe: { [QUOTE_SET_ADDRESSES_REQUEST]: true },
  });

  storage.addEffect({
    effect: async () => {
      client(setQuotePaymentMethodStripe);

      const { quote: data } = await client(quoteQuery);

      return ({
        tag: QUOTE_SET_PAYMENT_METHOD_STRIPE_RESPONSE,
        data,
      }: QuoteResponse);
    },
    subscribe: { [QUOTE_SET_PAYMENT_METHOD_STRIPE_REQUEST]: true },
  });

  storage.addEffect({
    effect: async (msg: QuoteRequest) => {
      if (msg.tag === QUOTE_SET_DISCOUNT_CODE_REQUEST) {
        const { setQuoteDiscountCode } = await client(setQuoteDiscountCodeMutation, {
          code: msg.code,
        });

        if (setQuoteDiscountCode.result !== "success") {
          storage.broadcastMessage(addMessage("DISCOUNT_CODE_FAILURE", "error"));
        }
        else {
          storage.broadcastMessage(addMessage("DISCOUNT_CODE_APPLIED", "success"));
        }

        const { quote: data } = await client(quoteQuery);

        return ({
          tag: QUOTE_SET_DISCOUNT_CODE_RESPONSE,
          data: removeExampleEmail(data),
        }: QuoteResponse);
      }
    },
    subscribe: { [QUOTE_SET_DISCOUNT_CODE_REQUEST]: true },
  });

  storage.addEffect({
    effect: async (msg: QuoteRequest) => {
      if (msg.tag === QUOTE_REMOVE_DISCOUNT_CODE_REQUEST) {
        await client(removeQuoteDiscountCode);

        const { quote: data } = await client(quoteQuery);

        return ({
          tag: QUOTE_REMOVE_DISCOUNT_CODE_RESPONSE,
          data: removeExampleEmail(data),
        }: QuoteResponse);
      }
    },
    subscribe: { [QUOTE_REMOVE_DISCOUNT_CODE_REQUEST]: true },
  });

  storage.addEffect({
    effect: async (msg: QuoteRequest) => {
      if (msg.tag === QUOTE_SET_EMAIL_REQUEST) {
        const { quote: data } = await client(quoteQuery);

        return ({
          tag: QUOTE_SET_EMAIL_RESPONSE,
          data: removeExampleEmail(data),
        }: QuoteResponse);
      }
    },
    subscribe: { [QUOTE_SET_EMAIL_REQUEST]: true },
  });
};

export default registerClient;
