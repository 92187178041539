/* @flow */

import type { Quote } from "shop-state/types";

import type {
  QuoteRequest,
  Data } from "@crossroads/shop-state/quote";

export const setQuoteDiscountCode = (code: string): QuoteRequest => ({
  tag: "quote/set_discount_code/request",
  code,
});

export const removeQuoteDiscountCode = (): QuoteRequest => ({
  tag: "quote/remove_discount_code/request",
});

export const getQuoteData = (d: Data): ?Quote => {
  return d.data || null;
};
