/* @flow */

import React from "react";
import { Route, Switch } from "react-router";
import { getQuoteData } from "state/quote";
import { useData } from "crustate/react";
import { QuoteData } from "state/data";

import Cart from "pages/Checkout/Cart";
import Overview from "pages/Checkout/Overview";

const CheckoutRoutes = (): React$Node => {
  const quoteState = useData(QuoteData);
  const quote = getQuoteData(quoteState);

  if (quoteState.state === "LOADING" || !quote) {
    return null;
  }

  return (
    <Switch>
      <Route exact path="/checkout" component={Cart} />
      <Route exact path="/checkout/overview" render={() => <Overview quote={quote} />} />
    </Switch>
  );
};

export default CheckoutRoutes;
