/* @flow */

import React, { useState, useEffect } from "react";
import cn from "classnames";
import { Input, Foldable, Button } from "@crossroads/ui-components";
import { useTranslate } from "@awardit/react-use-translate";
import { useHistory } from "react-router-dom";
import ChevronIcon from "icons/chevron.svg";
import CloseIcon from "icons/close.svg";
import useSubmitRedeem from "helpers/use-submit-redeem";
import { parseParams } from "helpers/location-search-string";
import { RECAPTCHA_KEY } from "entrypoint/shared";

import styles from "./styles.scss";

type Props = {
  onSuccess: () => void,
  onSubmitting?: boolean => void,
  isLocked?: boolean,
  className?: string,
};

const RedeemCard =
  ({ onSuccess, className, onSubmitting, isLocked = false }: Props): React$Node => {
    const t = useTranslate();
    const { location } = useHistory();
    const [processing, setProcessing] = useState(false);
    const [input, setInput] = useState<{ code: string, pin: string }>({ code: "", pin: "" });
    const { submit: submitRedeem, requiresPin, resetSubmit } = useSubmitRedeem();

    useEffect(() => {
      (async () => {
        const { code, pin } = parseParams(location.search);

        if (code && grecaptcha) {
          setProcessing(true);
          setInput({ code: String(code), pin: pin ? String(pin) : "" });

          grecaptcha.ready(async () => {
            const token = await grecaptcha.execute(RECAPTCHA_KEY, { action: "retain24validation" });

            const success = await submitRedeem({ code: String(code), pin: pin ? String(pin) : "" }, token);

            if (success === true) {
              onSuccess();
            }
            else {
              resetSubmit();
              setProcessing(false);
            }
          });
        }
      })();
    }, []);

    const submit = async (e: SyntheticEvent<HTMLFormElement>) => {
      e.preventDefault();

      if (isLocked) {
        return;
      }

      if (!input.code || (requiresPin && !input.pin)) {
        return;
      }

      setProcessing(true);

      if (onSubmitting) {
        onSubmitting(true);
      }

      grecaptcha.ready(async () => {
        const token = await grecaptcha.execute(RECAPTCHA_KEY, { action: "retain24validation" });
        const success = await submitRedeem(input, token);

        if (success === true) {
          setInput({ code: "", pin: "" });
          onSuccess();
        }

        setProcessing(false);

        if (onSubmitting) {
          onSubmitting(false);
        }
      });
    };

    const reset = () => {
      setInput({ code: "", pin: "" });
      resetSubmit();
      setProcessing(false);
    };

    return (
      <form className={cn(styles.block, className)} onSubmit={submit}>
        <div className={cn(styles.blockInner, { [styles.blockInnerFlipped]: requiresPin })}>
          <div className={styles.blockInnerFront}>
            <Input
              className={styles.input}
              labelClassName={styles.label}
              value={input.code}
              type="text"
              variant="light"
              label={t("CHECKOUT.REDEEM_LABEL_CODE")}
              placeholder={t("CHECKOUT.PLACEHOLDER_CODE")}
              onChange={e => setInput({ ...input, code: e.target.value.replace(/\s+/g, "") })}
            />
            <div className={cn(
              styles.blockInnerFrontPin,
              { [styles.requiresPin]: requiresPin })}
            >
              <Foldable open={requiresPin}>
                <Input
                  className={styles.input}
                  labelClassName={styles.label}
                  value={input.pin}
                  type="text"
                  variant="light"
                  label={t("CHECKOUT.REDEEM_LABEL_PIN")}
                  placeholder={t("CHECKOUT.PLACEHOLDER_PIN")}
                  onChange={e => setInput({ ...input, pin: e.target.value.replace(/\s+/g, "") })}
                />
              </Foldable>
            </div>
            <div className={styles.buttonWrapperCode}>
              <Button className={styles.buttonCode} variant="primary" loading={processing}>
                <span>{t("CHECKOUT.REDEEM_SUBMIT")}</span>
                <ChevronIcon />
              </Button>
            </div>
          </div>
          <div className={styles.blockInnerBack}>
            <Input
              className={styles.input}
              labelClassName={styles.label}
              value={input.pin}
              type="text"
              variant="light"
              label={t("CHECKOUT.REDEEM_LABEL_PIN")}
              placeholder={t("REDEEM.PLACEHOLDER_PIN")}
              onChange={e => setInput({ ...input, pin: e.target.value.replace(/\s+/g, "") })}
            />
            <Button className={styles.close} onClick={reset}>
              <CloseIcon />
            </Button>
            <div className={styles.buttonWrapperPin}>
              <Button className={styles.buttonPin} variant="primary" loading={processing}>
                <span>{t("CHECKOUT.REDEEM_SUBMIT")}</span>
                <ChevronIcon />
              </Button>
            </div>
          </div>
        </div>
      </form>
    );
  };

export default RedeemCard;
