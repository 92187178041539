/* @flow */

import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import { Route, Switch, useHistory } from "react-router";
import { usePreserveScrollPosition } from "helpers/react-router-on-navigation";
import CheckoutHeader from "app/Checkout/Header";
import Header from "app/Header";
import Routes from "routes";
import CheckoutRoutes from "routes/checkout";
import { Helmet } from "react-helmet-async";
import { useTranslate } from "@awardit/react-use-translate";
import { StoreInfoContext } from "entrypoint/shared";
import { UiProvider, useUi, MODE } from "helpers/ui";
import { NotificationBar, AppFooter, ButtonLink } from "@crossroads/ui-components";
import CookieConsent from "components/CookieConsent";
import SubscribeToNewsletter from "app/SubscribeToNewsletter";
import useCookie from "helpers/hooks/use-cookie";
import { CmsData } from "state/data";

import styles from "./styles.scss";

const App = (): React$Node => {
  const history = useHistory();
  const t = useTranslate();
  const { info, categories } = useContext(StoreInfoContext);
  const cookieOpts = { domain: `.${typeof window !== "undefined" ? window.location.host.replace(/:\d+$/, "") : ""}`, expires: 3650 };
  const [consent, setConsent, consentRead] = useCookie("cookie-consent", "initial", cookieOpts);
  const [hideNotificationBar, setHideNotificationBar] = useState(false);
  const [toggleCookieDialogue, setToggleCookieDialogue] = useState(false);

  const footerTerms = () => (
    <div className={styles.termsField}>
      <div>
        <Link className={styles.link} to="/villkor">
          {t("FOOTER.TERMS")}
        </Link>
        &nbsp;|&nbsp;
        <Link className={styles.link} to="/privacy-policy">
          {t("FOOTER.PRIVACY_POLICY")}
        </Link>
        &nbsp;|&nbsp;
        <ButtonLink
          className={styles.link}
          onClick={() => setToggleCookieDialogue(true)}
        >
          {t("FOOTER.COOKIES")}
        </ButtonLink>
      </div>
      <p dangerouslySetInnerHTML={{ __html: t("FOOTER.COPYRIGHT", { year: new Date().getFullYear() }) }} />
    </div>
  );

  usePreserveScrollPosition(history);

  return (
    <UiProvider>
      <CmsData.Provider url="cookie-consent">
        <Helmet
          titleTemplate={`${info.titlePrefix || ""} %s ${info.titleSuffix || ""}`}
          link={[
            { rel: "apple-touch-icon", sizes: "57x57", href: "/assets/browser/apple-touch-icon.png" },
            { rel: "apple-touch-icon", sizes: "60x60", href: "/assets/browser/apple-touch-icon.png" },
            { rel: "apple-touch-icon", sizes: "72x72", href: "/assets/browser/apple-touch-icon.png" },
            { rel: "apple-touch-icon", sizes: "76x76", href: "/assets/browser/apple-touch-icon.png" },
            { rel: "apple-touch-icon", sizes: "114x114", href: "/assets/browser/apple-touch-icon.png" },
            { rel: "apple-touch-icon", sizes: "120x120", href: "/assets/browser/apple-touch-icon.png" },
            { rel: "apple-touch-icon", sizes: "144x144", href: "/assets/browser/apple-touch-icon.png" },
            { rel: "apple-touch-icon", sizes: "152x152", href: "/assets/browser/apple-touch-icon.png" },
            { rel: "apple-touch-icon", sizes: "180x180", href: "/assets/browser/apple-touch-icon.png" },
            { rel: "apple-touch-icon", href: "/assets/browser/apple-touch-icon-120x120-precomposed.png" },
            { rel: "icon", type: "image/png", size: "16x16", href: "/assets/browser/favicon-16x16.png" },
            { rel: "icon", type: "image/png", size: "32x32", href: "/assets/browser/favicon-32x32.png" },
            { rel: "icon", type: "image/png", size: "96x96", href: "/assets/browser/favicon-96x96.png" },
            { rel: "manifest", href: "/manifest.json" },
            { rel: "mask-icon", href: "/assets/browser/safari-pinned-tab.svg", color: "#da2911" },
          ]}
          meta={[
            { name: "msapplication-TileColor", content: "#ffffff" },
            { name: "theme-color", content: "#ffffff" },
          ]}
          htmlAttributes={{ lang: info.locale.slice(0, 2) }}
        />
        <Switch>
          <Route exact path="/checkout/:step?">
            <div>
              <CheckoutHeader />
              <CheckoutRoutes />
            </div>
          </Route>
          <Route
            render={() => (
              <div className={styles.app}>
                <Header />

                <div className={styles.mainContent}>
                  <Routes />
                </div>

                {consentRead &&
                  <div className={styles.notices}>
                    <CookieConsent
                      isVisible={consentRead && (consent === "initial" || toggleCookieDialogue)}
                      consent={consent}
                      setConsent={setConsent}
                      consentRead={consentRead}
                      setToggleCookieDialogue={setToggleCookieDialogue}
                    />
                  </div>
                }

                <AppFooter
                  className={styles.footer}
                  activeLinks={["start", "categories", "brands", "search"]}
                  SubscribeToNewsletter={<SubscribeToNewsletter />}
                  categories={categories}
                  contentTerms={footerTerms}
                  MODE={MODE}
                  useUi={useUi}
                />

                {info.popUp && info.popUp.body && !hideNotificationBar &&
                  <NotificationBar
                    className={styles.notificationBar}
                    text={info.popUp.body}
                    onClose={() => setHideNotificationBar(true)}
                  />
                }
              </div>
            )}
          />
        </Switch>
      </CmsData.Provider>
    </UiProvider>
  );
};

export default App;
