/* @flow */

import type {
  AnalyticsProviderProps,
  AnalyticsContextType as GoogleAnalyticsContextType,
  ProductSubset,
  Order,
  Quote } from "shop-state/types";

import React, { createContext, useContext, useMemo } from "react";
import { WithAnalytics, AnalyticsContext as GoogleAnalyticsContext } from "@crossroads/analytics";
import fbq from "helpers/fb-pixel";
import { useTranslate } from "@awardit/react-use-translate";
  type Props = AnalyticsProviderProps & {
    children: React$Node,
  };

  type PropsInner = {
    children: React$Node,
  };

  type AnalyticsContextType = {
    ...GoogleAnalyticsContextType,
    registerModifyCart: (product: ProductSubset, diff: number, value: number) => void,
    registerCheckoutSuccess: (order: Order, coupon?: string) => void,
    registerBeginCheckoutProcess: (quote: Quote, value: number) => void,
    grantConsentNecessary: () => void,
    grantConsentStatistical: () => void,
    grantConsentMarketing: () => void,
    denyConsentStatistical: () => void,
    denyConsentMarketing: () => void,
  };

/**
   * No operation function
   */
export const noop = (): typeof undefined => undefined;

const AnalyticsContext = createContext<AnalyticsContextType>({
  g: noop,
  register: (): number => 0,
  unregister: noop,
  registerProductClick: noop,
  registerProductDetailsView: noop,
  registerModifyCart: noop,
  registerBeginCheckoutProcess: noop,
  registerCheckoutProgress: noop,
  registerCheckoutStep: noop,
  registerCheckoutSuccess: noop,
  grantConsentNecessary: noop,
  grantConsentStatistical: noop,
  grantConsentMarketing: noop,
  denyConsentStatistical: noop,
  denyConsentMarketing: noop,
  searchTerm: noop,
  viewedCart: noop,
});

const AnalyticsProvider = ({ children, ...rest }: Props): React$Node => {
  return (
    <WithAnalytics {...rest}>
      <AnalyticsProviderInner>
        {children}
      </AnalyticsProviderInner>
    </WithAnalytics>
  );
};

const AnalyticsProviderInner = ({ children }: PropsInner): React$Node => {
  const gaContext = useContext(GoogleAnalyticsContext);
  const t = useTranslate();

  const registerModifyCart = (product: ProductSubset, diff: number, value: number) => {
    if (diff === 0) {
      return;
    }

    gaContext.registerModifyCart(product, diff > 0 ? "add_to_cart" : "remove_from_cart", value);

    /* eslint-disable camelcase */
    fbq.event(diff > 0 ? "AddToCart" : "RemoveFromCart", {
      content_name: product.name,
      content_ids: [product.sku],
      content_type: "product",
      contents: [{ id: product.sku, quantity: diff }],
      value: Math.round(product.price.incVat * diff),
      currency: t("LOCALE.CURRENCY"),
    });
    /* eslint-enable camelcase */
  };

  const registerBeginCheckoutProcess = (quote: Quote, value: number) => {
    gaContext.registerBeginCheckoutProcess(quote.items, value);

    /* eslint-disable camelcase */
    fbq.event("InitiateCheckout", {
      contents: quote.items.map(i => ({
        id: i.product.sku,
        quantity: i.qty,
        type: "product",
      })),
      content_type: "product",
      value: Math.round(quote.grandTotal.incVat),
      currency: t("LOCALE.CURRENCY"),
    });
    /* eslint-enable camelcase */
  };

  const registerCheckoutSuccess = (order: Order, coupon?: string) => {
    gaContext.registerCheckoutSuccess(order, order.grandTotal.incVat || 0, coupon);

    /* eslint-disable camelcase */
    fbq.event("Purchase", {
      content_type: "product",
      contents: order.items.map(i => {
        const p = i.configOption ? i.configOption.product : i.product;

        return {
          id: p.sku,
          quantity: i.qty,
        };
      }),
      value: Math.round(order.grandTotal.incVat),
      currency: t("LOCALE.CURRENCY"),
    });
    /* eslint-enable camelcase */
  };

  const grantConsentStatistical = () => {
    gaContext.grantConsentStatistical();
  };

  const denyConsentStatistical = () => {
    gaContext.denyConsentStatistical();
  };

  const grantConsentMarketing = () => {
    fbq.init();
    gaContext.grantConsentMarketing();
  };

  const value = useMemo(() => ({
    g: gaContext.g,
    register: gaContext.register,
    unregister: gaContext.unregister,
    registerProductClick: gaContext.registerProductClick,
    registerProductDetailsView: gaContext.registerProductDetailsView,
    registerModifyCart,
    registerBeginCheckoutProcess,
    registerCheckoutProgress: gaContext.registerCheckoutProgress,
    registerCheckoutStep: gaContext.registerCheckoutStep,
    grantConsentNecessary: gaContext.grantConsentNecessary,
    registerCheckoutSuccess,
    grantConsentStatistical,
    grantConsentMarketing,
    denyConsentStatistical,
    denyConsentMarketing: gaContext.denyConsentMarketing,
    searchTerm: gaContext.searchTerm,
    viewedCart: gaContext.viewedCart,
  }), []);

  return (
    <AnalyticsContext.Provider value={value}>
      {children}
    </AnalyticsContext.Provider>
  );
};

const useAnalytics = (): AnalyticsContextType => {
  const context = useContext(AnalyticsContext);

  return context;
};

export {
  AnalyticsProvider,
  useAnalytics,
};
