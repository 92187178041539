/* @flow */

import type { Node as ReactNode } from "react";
import { useTranslate } from "@awardit/react-use-translate";

import React from "react";
import { Wrapper } from "@crossroads/ui-components";

import styles from "./styles.scss";

type Props = {
  children: ReactNode,
  right: ReactNode,
};

const Container = ({ children, right }: Props): React$Node => {
  const t = useTranslate();
  return (
    <Wrapper className={styles.block}>
      <h1>{t("CHECKOUT.STEPS.CART.SHORT")}</h1>
      <div className={styles.checkoutContainer}>
        <div className={styles.left}>
          {children}
        </div>
        <div className={styles.right}>
          {right}
        </div>
      </div>
    </Wrapper>
  );
};

export default Container;
