/* @flow */

import type { QuoteItem } from "shop-state/types";

import React, { useState, useEffect } from "react";
import { useTranslate } from "@awardit/react-use-translate";
import { addMessage } from "@crossroads/shop-state/messages";
import { QuoteData } from "state/data";
import useFormat from "helpers/hooks/use-format";
import CustomerServiceLink from "pages/Checkout/CustomerServiceLink";
import { useSendMessage, useData } from "crustate/react";
import { removeQuoteItem } from "@crossroads/shop-state/quote";
import { Dialogue, Checkbox, Foldable, Button } from "@crossroads/ui-components";
// import { Box, BoxHeader } from "components/Box";
import ButtonLink from "components/ButtonLink";
import Container from "pages/Checkout/Container";
import CartSummary from "components/CartSummary";
import CartItem from "pages/Checkout/CartItem";
import DiscountCode from "pages/Checkout/DiscountCode";
import RedeemCard from "components/RedeemCard";
import CloseIcon from "icons/close.svg";
import Cookies from "js-cookie";
import Summary from "../Summary";
import { useAnalytics } from "context/analytics";

import styles from "./styles.scss";
type RedeemProps = {
  redeem: ?{ value: number },
  onSubmitting: boolean => void,
  isSubmitting: boolean,
  isLocked: boolean,
  updatingQuote: boolean,
  setUpdatingQuote: boolean => void,
};

const QUOTE_ITEM_UPDATING_STATES = new Set([
  "UPDATING_ITEM",
  "REMOVING_ITEM",
]);

const Cart = (): React$Node => {
  const t = useTranslate();
  const sendMessage = useSendMessage();
  const quote = useData(QuoteData);
  const { formatPrice } = useFormat();
  const [open, setOpen] = useState(true);
  const [activeItem, setActiveItem] = useState<QuoteItem | null>(null);
  const [confirmDialogueOpen, setConfirmDialogueOpen] = useState(false);
  const [submittingCode, setSubmittingCode] = useState(false);
  const [updatingQuote, setUpdatingQuote] = useState<boolean>(false);
  const [subToNewsletter, setSubToNewsletter] = useState<any>(Cookies.get("opt-email") === "true");
  const activeRedeem = quote.data && quote.data.retain24validation;
  const discountTotal = quote.data && quote.data.discountTotal ? quote.data.discountTotal : 0;
  const analytics = useAnalytics();

  useEffect(() => {
    Cookies.set("opt-email", subToNewsletter.toString());
  }, [subToNewsletter]);

  useEffect(() => {
    if (QUOTE_ITEM_UPDATING_STATES.has(quote.state)) {
      setUpdatingQuote(true);
    }
    else {
      setUpdatingQuote(false);
    }
  }, [quote.state]);

  useEffect(() => {
    if (quote.data) {
      analytics.viewedCart(quote.data.grandTotal.incVat, quote.data.items.map(item => {
        return {
          sku: item.sku ?? item.product.sku,
          name: item.product.name,
          price: {
            exVat: item.rowTotal.exVat,
            incVat: item.rowTotal.incVat,
            vat: item.rowTotal.incVat - item.rowTotal.exVat,
          },
          qty: item.qty,
          attributes: {
            manufacturer: item.product.attributes.manufacturer,
          },
          categories: item.product.categories,
        };
      }));
    }
  }, []);

  const remove = (item: QuoteItem) => {
    sendMessage(removeQuoteItem(item.itemBuyRequest));
  };

  const confirmRemove = (item: QuoteItem) => {
    setConfirmDialogueOpen(true);
    setActiveItem(item);
  };

  const processingItem = typeof quote.processingItem !== "undefined" ? quote.processingItem : null;

  if (!quote.data) {
    return null;
  }

  const grandTotal = quote.data.grandTotal.incVat;
  const subTotal = quote.data.subTotal;
  const shippingTotal = quote.data.shipping &&
  quote.data.shipping.total ? quote.data.shipping.total.incVat : 0;
  const voucherValue = quote.data.retain24validation ? quote.data.retain24validation.value : 0;
  const voucherValueAfter = Math.max(voucherValue -
    (subTotal.incVat - Math.abs(discountTotal)) - shippingTotal, 0);
  const { items } = quote.data;

  return (
    <Container
      right={
        <>
          {quote.data.retain24validation &&
            <div className={styles.giftCardInfo}>
              <table>
                <tbody>
                  <tr>
                    <td>{t("CART.GIFT_CARD_BALANCE")}</td>
                    <td>{formatPrice(voucherValue)}</td>
                  </tr>
                  <tr>
                    <td>{t("CART.GIFT_CARD_BALANCE_POST_SHOPPING")}</td>
                    <td>{formatPrice(voucherValueAfter)}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          }
          <div>
            <CartSummary open={open} setOpen={setOpen}>
              <div className={styles.submitButtonContainer}>
                <div className={styles.totalPrice}>
                  <span className={styles.tableSection}><strong>{t("CART.GRANDTOTAL")}</strong></span>
                  <span className={styles.tableSection}>
                    <strong>
                      {formatPrice(grandTotal)}
                    </strong>
                  </span>
                </div>
                <ButtonLink
                  className={styles.submitButton}
                  variant="primary"
                  to="/checkout/overview"
                >
                  {t("CART.TO_CHECKOUT")}
                </ButtonLink>
              </div>
            </CartSummary>
            <CustomerServiceLink />
          </div>
        </>
      }
    >
      {confirmDialogueOpen &&
      <Dialogue
        className={styles.confirmDialogue}
        open={confirmDialogueOpen}
        setOpen={() => setConfirmDialogueOpen(false)}
        title={t("CART.REMOVE_PRODUCT")}
        closeIcon={<div className={styles.closeIconWrapper}><CloseIcon /></div>}
        primaryAction={t("CART.REMOVE_APPROVE")}
        secondaryAction={t("CONFIRM_DIALOG.CANCEL")}
        onPrimaryAction={() => {
          if (activeItem) {
            setConfirmDialogueOpen(false);
            remove(activeItem);
          }
        }}
        onSecondaryAction={() => setConfirmDialogueOpen(false)}
      >
        {t("CART.CONFIRM_TEXT", {
          itemName: `${activeItem?.product.name || ""}, ${activeItem?.product.attributes.manufacturer || ""}`,
        })}
      </Dialogue>}
      <div className={styles.cart}>
        {items.map(x => {
          return (
            <CartItem
              key={x.product.name}
              item={x}
              quote={quote}
              processingItem={processingItem}
              confirmRemove={confirmRemove} />
          );
        })}

        <Summary />

        <div className={styles.ctaContainer}>
          <Redeem
            isLocked={false}
            redeem={activeRedeem}
            isSubmitting={submittingCode}
            updatingQuote={updatingQuote}
            setUpdatingQuote={setUpdatingQuote}
            onSubmitting={setSubmittingCode}
          />

          <div className={styles.optEmail}>
            <Checkbox
              name="opt-out"
              value={subToNewsletter}
              defaultChecked={subToNewsletter}
              onChange={e => setSubToNewsletter(e.target.checked)}
            >
              {t("CHECKOUT.OPT_EMAIL")}
            </Checkbox>
          </div>
        </div>
      </div>
    </Container>
  );
};

const Redeem = ({
  redeem,
  isSubmitting,
  onSubmitting,
  isLocked,
  updatingQuote,
  setUpdatingQuote,
}: RedeemProps) => {
  const t = useTranslate();
  const sendMessage = useSendMessage();
  const [redeemOpen, setRedeemOpen] = useState(false);
  const [discountOpen, setDiscountOpen] = useState(false);
  const data = useData(QuoteData);

  useEffect(() => {
    if (QUOTE_ITEM_UPDATING_STATES.has(data.state)) {
      setUpdatingQuote(true);
    }
    else {
      setUpdatingQuote(false);
    }
  }, [data.state]);

  if (redeem && !data.data) {
    return null;
  }

  const coupon = data.data && data.data.coupon;

  const onSuccess = () => {
    sendMessage(addMessage("success", "success"));
  };

  return (
    <div className={styles.redeem}>
      <div className={styles.redeemInner}>
        <div className={styles.redeemButtons}>
          <Button
            disabled={discountOpen}
            className={styles.discountBtn}
            variant="ghost"
            onClick={() => {
              if (!isSubmitting) {
                setDiscountOpen(!discountOpen);
                setRedeemOpen(false);
              }
            }}
          >
            {t("CHECKOUT.DISCOUNT_CODE.CHECKBOX_LABEL")}
          </Button>
          <Button
            className={styles.redeemBtn}
            disabled={redeemOpen}
            variant="primary"
            onClick={() => {
              if (!isSubmitting && !isLocked) {
                setRedeemOpen(!redeemOpen);
                setDiscountOpen(false);
              }
            }}
          >
            {t("CHECKOUT.REDEEM_BUTTON_LABEL")}
          </Button>
        </div>

        <div className={styles.redeemCard}>
          <Foldable open={redeemOpen || discountOpen} className={styles.redeemFoldable}>
            {redeemOpen &&
              <RedeemCard
                isLocked={isLocked}
                className={styles.redeemCardInner}
                onSubmitting={onSubmitting}
                onSuccess={onSuccess}
              />
            }

            {discountOpen &&
              <DiscountCode
                disabled={updatingQuote}
                coupon={coupon}
              />
            }
          </Foldable>
        </div>
      </div>
    </div>
  );
};

export default Cart;
