/* @flow */

import React from "react";
import { useTranslate } from "@awardit/react-use-translate";
import useFormat from "helpers/hooks/use-format";
import { useData } from "crustate/react";
import { QuoteData } from "state/data";
import styles from "./styles.scss";

const Summary = (): React$Node => {
  const t = useTranslate();
  const data = useData(QuoteData);
  const { formatPrice } = useFormat();

  if (!data.data) {
    return null;
  }

  const { subTotal, retain24validation, discountTotal, grandTotal, shipping,
  } = data.data;

  const shippingTotal = shipping && shipping.total ? shipping.total.incVat : 0;
  const voucherValue = retain24validation ? retain24validation.value : 0;
  const voucherValueAfter = Math.max(voucherValue -
    (subTotal.incVat - Math.abs(discountTotal)) - shippingTotal, 0);
  const payedByVoucher = voucherValue - voucherValueAfter;

  return (
    <div className={styles.block}>
      <table>
        <tbody>
          {grandTotal.incVat !== subTotal.incVat &&
            <tr>
              <td>{discountTotal !== 0 ?
                t("CART.SUB_TOTAL_WITH_DISCOUNT") :
                t("CART.SUB_TOTAL")
              }
              </td>
              <td>{formatPrice(subTotal.incVat)}</td>
            </tr>
          }

          {discountTotal !== 0 &&
            <tr>
              <td>{t("CART.DISCOUNT")}</td>
              <td>{formatPrice(discountTotal)}</td>
            </tr>
          }

          {voucherValue > 0 &&
            <tr>
              <td>{t("CART.SHIPPING")}</td>
              <td>{formatPrice(shippingTotal)}</td>
            </tr>
          }

          {voucherValue > 0 &&
            <tr>
              <td>{t("CART.PAY_VOUCHER")}</td>
              <td>-{formatPrice(payedByVoucher)}</td>
            </tr>
          }

          <tr>
            <td>{t("CART.GRAND_TOTAL")}</td>
            <td className={styles.total}>{formatPrice(grandTotal.incVat)}</td>
          </tr>

          {voucherValue > 0 &&
            <tr>
              <td>{t("CART.AFTER_PURCHASE")}</td>
              <td>{formatPrice(Math.max(voucherValueAfter, 0))}</td>
            </tr>
          }
        </tbody>
      </table>
    </div>
  );
};

export default Summary;
