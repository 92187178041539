/* @flow */

import type { QuoteItem, QuoteItemConfigurable } from "shop-state/types";
import type {
  Data as QuoteData,
} from "@crossroads/shop-state/quote";

import React from "react";
import cn from "classnames";
import { Link } from "react-router-dom";
import QtyPicker from "components/QtyPicker";
import { useSendMessage } from "crustate/react";
import { updateQuoteItemQty } from "@crossroads/shop-state/quote";
import useFormat from "helpers/hooks/use-format";
import { pointsPriceByID } from "helpers/points";
import { useAnalytics } from "context/analytics";

import styles from "./styles.scss";

type Props = {
  item: QuoteItem | QuoteItemConfigurable,
  quote: QuoteData,
  processingItem: null | string,
  confirmRemove: (item: QuoteItem) => void,
};

const CartItem =
({ item, quote, processingItem, confirmRemove }: Props): React$Node => {
  const product = item.configOption ? {
    ...item.product,
    ...item.configOption.product,
    categories: item.product.categories,
  } : item.product;
  const { manufacturer } = product.attributes;
  const sendMessage = useSendMessage();
  const { formatPrice, formatPoints } = useFormat();
  const pointsPrice = pointsPriceByID(item.product.pointsPrices, "vanilla");
  const analytics = useAnalytics();

  return (
    <div
      key={item.itemBuyRequest}
      className={
        cn(styles.item, {
          [styles.processing]: processingItem === item.itemBuyRequest,
          [styles.disabled]: quote.state === "UPDATING_ITEM",
        })}
    >
      <div className={styles.left}>
        <div className={styles.imageWrapper}>
          <img
            className={styles.image}
            alt={product.name}
            src={product.attributes.image?.x1}
          />
          <div className={styles.info}>
            <div>
              <Link
                to={{
                  pathname: product.url,
                  state: { hint: {
                    type: "product",
                    product,
                    image: product.attributes.image?.x1,
                  } },
                }}
                className={styles.name}
              >
                {product.name}
              </Link>

              <Link
                to={{
                  pathname: `/brand/${encodeURIComponent(manufacturer)}`,
                  state: { hint: { type: "category", category: { name: manufacturer } } },
                }}
                className={styles.brand}
              >
                {manufacturer}
              </Link>

              {item.bundleOptions &&
                <ul className={styles.bundleOptions}>
                  {item.bundleOptions.map(o => o.products.map(p =>
                    <li key={o.title}>{p.product.name}</li>
                  ))}
                </ul>
              }
            </div>
          </div>
        </div>
      </div>

      <div className={styles.right}>
        <QtyPicker
          className={styles.qtyPicker}
          value={item.qty}
          min={0}
          setValue={(v: number) => {
            if (v > 0) {
              sendMessage(updateQuoteItemQty(item.itemBuyRequest, v));
            }
            else {
              confirmRemove(item);
            }

            analytics.registerModifyCart({
              ...product,
              qty: Math.abs(v - item.qty),
            }, v - item.qty, product.price.incVat);
          }} />
        <div className={styles.priceWrapper}>
          <p>
            {pointsPrice ?
              formatPoints(item.qty * pointsPrice.points.max.exVat) :
              formatPrice(item.qty * item.product.price.incVat)}
          </p>
        </div>
      </div>
    </div>
  );
};

export default CartItem;
